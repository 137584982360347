@font-face {
  font-family: 'Cabrion Bold';
  src: local('Cabrion Bold'), local('Cabrion-Bold'),
    url('./fonts/Cabrion-Bold.woff2') format('woff2'),
    url('./fonts/Cabrion-Bold.woff') format('woff'),
    url('./fonts/Cabrion-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Cabrion';
  src: local('Cabrion Regular'), local('Cabrion-Regular'),
    url('./fonts/Cabrion-Regular.woff2') format('woff2'),
    url('./fonts/Cabrion-Regular.woff') format('woff'),
    url('./fonts/Cabrion-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
}

#Body {
  align-self: center;
  height: auth;
  min-height: 100vh;
  width: 100%;
  background-color: #181818;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#Row {
  margin: 10% 0;
  width: 90%;
  height: 80%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #FFFFFF42;
  box-shadow: 0px 0px 8px #FFFFFF42;
}

#ValueContainer {
  margin: 0 0 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2% 2%;
  width: 86%;
  height: 34%;
  background-color: #0000001F;
  border-radius: 8px;
  box-shadow: 0px 1px 4px #FFFFFF42;
}

#ValueTitle {
  color: white;
  font-family: 'Cabrion Bold';
  text-transform: uppercase;
  margin: 2% 0;
}

#ValueText {
  color: white;
  font-family: 'Cabrion';
  margin: 2% 0;
}